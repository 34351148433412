import {
  TableInstance,
  useColumnOrder,
  useExpanded,
  useFlexLayout,
  useGlobalFilter,
  useGroupBy,
  usePagination,
  useResizeColumns,
  useSortBy,
  useTable,
} from "react-table";
import { useExportData } from "react-table-plugins";
import { TableScrollContainer } from "./TableScrollContainer";
import { TableBody } from "./TableBody";
import { TableHeader } from "./TableHeader";
import { TableWrapper } from "./TableWrapper";
import { TableProps } from "./types/index";
import { Center } from "../Center";
import { getExportFileBlob, getExportFileName } from "./utils/exportData";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { TableFilter } from "./TableFilter";
import { TablePagination } from "./TablePagination";
import { TableToolbar } from "./TableToolbar.tsx";
import { CSSProperties } from "react";
import PulseLoader from "react-spinners/PulseLoader";

export const defaultColumnValues = {
  minWidth: 200,
  width: 150,
};

export const Table = <T extends object>({
  data,
  columns,
  onSearchSubmit,
  onRefresh,
  filters,
  allowExport = false,
  manualPagination = true,
  isLoading,
  recordCount,
  pageCount = 25,
  pageSize = 10,
  pageIndex: pageIndexProp = 0,
  handleColumnSort,
  sort,
  onRowClick,
  onNextPage,
  onPreviousPage,
  erroredColumns = [],
  downloadOptions = [],
  TableButtons,
  FilterComponent,
  defaultColumn,
  tableRowQuantityChange,
  userColumns,
  hiddenColumns = [],
  hideSearch,
  hideDownload,
}: TableProps<T>) => {
  const extendedUseTable = useTable as (...args: any[]) => TableInstance<T>;
  const { isFilterSidebarOpen, theme } = useSelector((state: RootState) => {
    return state.tableSlice;
  });

  const defaultColumnSize = defaultColumn || defaultColumnValues;

  const useTableData = extendedUseTable(
    {
      columns,
      data,
      defaultColumn: defaultColumnSize,
      manualPagination,
      pageCount:
        manualPagination === true
          ? pageCount
          : Math.round(data.length / pageSize),
      initialState: {
        pageIndex: pageIndexProp,
        pageSize,
        columnOrder: [],
        hiddenColumns: userColumns
          ? Object.keys(userColumns).filter((key) => !userColumns[key])
          : hiddenColumns,
      },
      paginateExpandedRows: false,
      getExportFileBlob,
      getExportFileName: getExportFileName("filename"),
    },
    useGlobalFilter,
    useColumnOrder,
    useGroupBy,
    useFlexLayout,
    useSortBy,
    useExpanded,
    usePagination,
    useResizeColumns,
    useExportData
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    visibleColumns,
    previousPage,
    nextPage,
    prepareRow,
    state: { pageIndex },
    exportData,
  } = useTableData;

  const handleNextPage = () => {
    if (onNextPage) {
      onNextPage();
    } else {
      nextPage();
    }
  };

  const handlePreviousPage = () => {
    if (onPreviousPage) {
      onPreviousPage();
    } else {
      previousPage();
    }
  };

  const showToolbar = allowExport || filters;

  const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
    zIndex: 1,
  };

  return (
    <div className="flex flex-col flex-1 w-full overflow-hidden">
      {showToolbar && (
        <TableToolbar
          filters={filters}
          onSearchSubmit={onSearchSubmit}
          onRefresh={onRefresh}
          useTableData={useTableData}
          allowExport={allowExport}
          downloadOptions={downloadOptions}
          exportFunction={() => exportData("csv", false)}
          TableButtons={TableButtons}
          userColumns={userColumns}
          hideSearch={hideSearch}
          hideDownload={hideDownload}
          recordCount={recordCount}
        />
      )}

      <div className="flex flex-1 overflow-hidden">
        {filters && isFilterSidebarOpen && (
          <TableFilter filters={filters} FilterComponent={FilterComponent} />
        )}

        <TableWrapper>
          {isLoading && (
            <div className="absolute w-full h-full">
              <Center>
                <PulseLoader
                  color={theme === "dark" ? "#ffffff" : "#000000"}
                  loading={isLoading}
                  cssOverride={override}
                  size={20}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </Center>
            </div>
          )}
          <TableScrollContainer>
            <div
              {...getTableProps()}
              className="flex flex-col flex-1 min-w-full overflow-hidden Table"
            >
              <TableHeader
                headerGroups={headerGroups}
                sort={sort}
                handleColumnSort={handleColumnSort}
                erroredColumns={erroredColumns}
              />

              {!isLoading && data.length === 0 ? (
                <div className="w-full h-full p-5 text-center text-muted">
                  No results
                </div>
              ) : (
                <TableBody
                  page={page}
                  isLoading={false}
                  visibleColumns={visibleColumns}
                  getTableBodyProps={getTableBodyProps}
                  onRowClick={(row) => {
                    onRowClick ? onRowClick(row) : null;
                  }}
                  prepareRow={prepareRow}
                  erroredColumns={erroredColumns}
                />
              )}
            </div>
          </TableScrollContainer>
          <div className="px-4">
            <TablePagination<T>
              page={page}
              previousPage={handlePreviousPage}
              nextPage={handleNextPage}
              pageIndex={pageIndex}
              pageCount={pageCount}
              pageSize={pageSize}
              total={manualPagination === true ? recordCount : data.length}
              tableRowQuantityChange={tableRowQuantityChange}
            />
          </div>
        </TableWrapper>
      </div>
    </div>
  );
};
