/* eslint-disable @typescript-eslint/no-explicit-any */
import { DEFAULT_WEIGHT_UNIT } from "constants/misc";
import { WeightUnits, WeightUnitSettings } from "interfaces/weight-units.type";
import {
  convertDatabaseToValue,
  convertGramsToKilos,
} from "utils/convertGramsToKilos";
import { convertKilosToPounds } from "utils/convertPoundsToKilos";
import { Button } from "components/atoms/Button";
import { ChangeDefaultUnit } from "./changeWeightType";
import { Dispatch } from "@reduxjs/toolkit";


export const PrintWeight = ({
  weightUnit = DEFAULT_WEIGHT_UNIT,
  value,
  isVolume = false,
}: {
  weightUnit: WeightUnits;
  value: number;
  isVolume?: boolean;
}) => {
  return (
    <>
      {weightUnit === WeightUnits.kg
        ? convertGramsToKilos(convertDatabaseToValue(value))
        : convertKilosToPounds(
            convertGramsToKilos(convertDatabaseToValue(value))
          )}{" "}
      {WeightUnitSettings[weightUnit]?.label}
    </>
  );
};

export const PrintWeightUnit = ({
  value,
  salesAccountUUID,
  dispatch
}: {
  value: string;
  salesAccountUUID: string;
  dispatch: Dispatch<any>;
}) => {
  return (
    <>
      <div className="flex justify-between items-baseline">
        {value}
        <Button
            colorScheme="primary"
            onClick={() =>
              {value == "kg" ? ChangeDefaultUnit("pound", salesAccountUUID, dispatch) : ChangeDefaultUnit("kg", salesAccountUUID, dispatch)}
            }
          >
            Change
          </Button>
      </div>
    </>
  );
};
