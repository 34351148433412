/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch } from "@reduxjs/toolkit";
import { fetchSettings, updateSettings } from "services/api/settings";
import { setSettingsData } from "store/settingsSlice/index.slice";

export const ChangeDefaultUnit = (value : string, salesAccountUUID: string, dispatch: Dispatch<any>) => {
    
    updateSettings(salesAccountUUID, value).then(() =>{
        fetchSettings(salesAccountUUID).then(({ data, error }) => {
          if (!error) {
            dispatch(setSettingsData(data));
          }
        });
      });
}