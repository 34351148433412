import { CheckmarkFilled20 } from "@carbon/icons-react";
import { Heading } from "components/atoms/Heading";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { IQuotedRate } from "models/quote/quote.model";
import { Checkbox } from "components/atoms/Checkbox";
import { RoleGuard } from "components/atoms/RoleGuard";
import { Roles } from "enum/roles.enum";
import { DEFAULT_CURRENCY_ISO } from "constants/misc";
import { IService } from "models/service/service.model";
import { ISupplier } from "models/supplier/supplier.model";
import { ITariff } from "models/tariff/tariff.model";
import { ICarrier } from "models/carrier/carrier.model";
import { PrintCurrency } from "components/atoms/PrintCurrency";
import { CalculateRateValues } from "utils/calculateRateTotals";
import { printTariffName } from "utils/printTariffName";
import { CarrierTypes } from "enum/carrier-types.enum";
import {
  DomesticCarriersDatabaseIDs,
  IntlCarriersDatabaseIDs,
} from "enum/carrier-mappings.enum";
import { ReactNode, useEffect, useState } from "react";
import { RatesTestIds } from "./RatesTestIds.enum";
import { Button } from "components/atoms/Button";
import { Panel } from "components/atoms/Panel";
import { Logo } from "components/atoms/LogoCircle";
import { kebabCase, toUpper } from "lodash";
import { fetchExternalQuote } from "../../../services/api/quotes/index";
import { Tooltip } from "components/atoms/Tooltip";
import { FetchSingleExternalQuoteResponseDto } from "services/api/quotes/dtos/fetch-single-quote-response.dto";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { convertKilosToPounds } from "utils/convertPoundsToKilos";

interface RateRow {
  number: number;
  rate: IQuotedRate;
  allowSelect: boolean;
  isInternationalShipment: boolean;
  onSelect?(rate: IQuotedRate): void;
  onCheck?(rate: IQuotedRate): void;
  isSelected: boolean;
  isCheckable: boolean;
  isChecked?: boolean;
}

const RateRowCellContainer = ({
  children,
  minWidth,
}: {
  minWidth?: number;
  children: ReactNode;
}) => {
  return (
    <div className="flex items-center flex-1 px-4 py-4" style={{ minWidth }}>
      <div className="flex-1">{children}</div>
    </div>
  );
};

const RateRowCellValue = ({
  title,
  value,
}: {
  title: string | ReactNode;
  value: number | string;
}) => {
  const { salesAccount } = useSelector((state: RootState) => {
    return state.settingsSlice;
  });

  return (
    <RateRowCellContainer>
      <div className="mb-2 text-sm leading-none text-muted">{title}</div>
      <div className="font-medium leading-none text-md">
        {typeof value === "string" ? (
          value
        ) : (
          <PrintCurrency
            value={value}
            currencyCode={
              salesAccount?.baseCurrencyCode || DEFAULT_CURRENCY_ISO
            }
          />
        )}
      </div>
    </RateRowCellContainer>
  );
};

const CellDivider = () => {
  return <div className="border-r border-gray6"></div>;
};

const RateRowCellHeader = ({
  carrierType,
  service,
  carrier,
  supplier,
  tariff,
  deliveryDate,
  actualZone,
  chargeableWeight,
  financialCompany,
}: {
  carrierType: CarrierTypes;
  service: IService;
  supplier: ISupplier;
  tariff: ITariff | null;
  carrier: ICarrier;
  deliveryDate: Date;
  actualZone: number;
  chargeableWeight: string;
  financialCompany: number;
}) => {
  const { loggedInUser } = useSelector((state: RootState) => {
    return state.authSlice;
  });
  const formattedDate =
    deliveryDate && financialCompany === 8
      ? new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
          timeZone: "UTC",
        }).format(new Date(deliveryDate))
      : "";
  return (
    <div className="flex items-center justify-between w-full">
      <div className="flex items-center">
        <div className="mr-3">
          <Logo
            size="md"
            imageUrl={`/images/logos/${kebabCase(
              carrier?.name?.toLowerCase()
            )}.png`}
          />
        </div>
        <div>
          <Heading size="sm">
            {carrier?.name} –{" "}
            {financialCompany === 8
              ? service?.name.replace(/_/g, " ").replace(/fedex/gi, "")
              : service?.name}
          </Heading>
          {(supplier || tariff) && (
            <div className="text-xs leading-none text-gray11 mt-1.5">
              <RoleGuard
                userRoles={loggedInUser["https://connexx.com/roles"]}
                showForRoles={[Roles.ITD_STAFF]}
              >
                {supplier?.name},{" "}
              </RoleGuard>
              {tariff && printTariffName(tariff, carrierType)}
            </div>
          )}
        </div>
      </div>
      {financialCompany === 8 && actualZone && (
        <>
          <div className="text-right align-items-end">
            <div className="text-xs leading-none  mt-1.5">
              {"Estimated Delivery: "}
              {formattedDate}
            </div>
            <div className="text-xs leading-none  mt-1.5">
              {"Chargeable Weight: "}
              {chargeableWeight}
            </div>
            <div className="text-xs leading-none  mt-1.5">
              {"Zone: "}
              {actualZone == 0 ? "Not available" : actualZone}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const RateRowCellButton = ({
  onClick,
  isSelected,
  testId,
}: {
  onClick?(): void;
  isSelected: boolean;
  testId: string;
}) => {
  return (
    <Button
      testId={testId}
      isBlock
      colorScheme={isSelected ? "selected" : "primary"}
      size="sm"
      onClick={onClick}
    >
      <div className="flex items-center justify-center ">
        {isSelected ? <CheckmarkFilled20 /> : "Select"}
      </div>
    </Button>
  );
};
const fetchSurchargeInfo = async (salesAccountUUID, shipmentID, service) => {
  try {
    const response: FetchSingleExternalQuoteResponseDto =
      await fetchExternalQuote(salesAccountUUID, shipmentID);
    const externalQuote = Array.isArray(response.data)
      ? response.data
      : [response.data];
    const filteredData = externalQuote.filter(
      (x) => toUpper(x.serviceName) === service.name
    );
    return filteredData[0];
  } catch (error) {
    console.error("Error fetching surcharges:", error);
    return null;
  }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const RateRow = ({
  number,
  rate,
  allowSelect,
  onSelect,
  isInternationalShipment,
  onCheck,
  isSelected,
  isCheckable,
  isChecked,
}: RateRow) => {
  const { tariff, supplier, carrier, service, carrierType } = rate;

  const { financialCompany, salesAccountUUID, salesAccountID, loggedInUser } =
    useSelector((state: RootState) => {
      return state.authSlice;
    });
  const [externalQuote, setSurchargeInfo] = useState(null);
  const chargableWeight: string = ["LB", "LBS"].includes(
    externalQuote?.chargableWeightUnit
  )
    ? externalQuote?.chargableWeight + " " + externalQuote?.chargableWeightUnit
    : externalQuote?.chargableWeight +
      " " +
      externalQuote?.chargableWeightUnit +
      " / " +
      convertKilosToPounds(externalQuote?.chargableWeight) +
      " LB";

  const rateValues = CalculateRateValues({
    rate,
    isInternationalShipment,
    financialCompany: financialCompany || 1,
  });

  useEffect(() => {
    if (financialCompany === 8 && salesAccountUUID && rate.shipmentId) {
      fetchSurchargeInfo(salesAccountUUID, rate.shipmentId, service).then(
        setSurchargeInfo
      );
    }
  }, [financialCompany, salesAccountUUID, rate.shipmentId]);

  const isYodelOrEvri =
    carrier.id === DomesticCarriersDatabaseIDs.EVRI ||
    carrier.id === DomesticCarriersDatabaseIDs.YODEL ||
    carrier.id === IntlCarriersDatabaseIDs.AMAZON.toString();

  const adjustedTotal =
    financialCompany === 8 && externalQuote?.totalSurcharges
      ? externalQuote.netChargeSales / 100
      : rateValues.total / 100;

  const freightPrice =
    financialCompany === 8 && externalQuote?.totalSurcharges
      ? externalQuote.salesFreight / 100
      : rateValues.netPrice / 100;

  const loggedInuserEmail = loggedInUser?.email;

  // Peter Hathaway wants to show Gatineau's rates to Dawood's customers. Dawood is the reseller. Gatineau is Dawood's customer.
  const isNeilOrDAWOOD =
    loggedInuserEmail === "nc@itdglobal.com" ||
    loggedInuserEmail === "accounts@magnaservice.co.uk";

  const hideOnTheseAccounts =
    !isNeilOrDAWOOD &&
    ((Number(salesAccountID) === 225671 && isInternationalShipment) ||
      Number(salesAccountID) === 225721);

  // const chargableWeight : string = externalQuote?.chargableWeightUnit === 'LB' ?  (
  //   externalQuote?.chargableWeight +
  //             " " +
  //   externalQuote?.chargableWeightUnit
  // ):(
  //   externalQuote?.chargableWeight +
  //             " " +
  //   externalQuote?.chargableWeightUnit + " / "+convertKilosToPounds(externalQuote?.chargableWeight)+"LB"
  // );
  return (
    <div data-testid={`${RatesTestIds.RATE_ROW}_${number}`} className="flex">
      {isCheckable && (
        <div className="mt-3 mr-3">
          <Checkbox
            checked={isChecked}
            {...(onCheck && {
              onCheckedChange: () => onCheck(rate),
            })}
          />
        </div>
      )}

      <Panel
        className="w-full mb-4"
        // className={cx(
        //   " bg-white border border-gray7 rounded-md flex-1 relative overflow-hidden",
        //   {
        //     "ring-2 ring-primary2 border-primary5": isSelected,
        //   }
        // )}
      >
        <div className="flex items-center p-3 border-b border-gray6">
          <RateRowCellHeader
            carrierType={carrierType}
            tariff={tariff}
            supplier={supplier}
            service={service}
            carrier={carrier}
            deliveryDate={externalQuote?.estimatedDeliveryDate}
            actualZone={externalQuote?.zone}
            chargeableWeight={chargableWeight}
            financialCompany={financialCompany}
          />
          {allowSelect && (
            <div className="ml-auto pl-5" style={{ width: 100 }}>
              <RateRowCellButton
                testId={`${RatesTestIds.SELECT_RATE_BUTTON}_${number}`}
                isSelected={isSelected}
                {...(onSelect && { onClick: () => onSelect(rate) })}
              />
            </div>
          )}
        </div>
        {!hideOnTheseAccounts ? (
          <div className="flex">
            <RateRowCellValue
              title="Freight price"
              value={isYodelOrEvri || freightPrice === 0 ? "N/A" : freightPrice}
            />
            <CellDivider />
            {financialCompany !== 8 && (
              <>
                <RateRowCellValue
                  title="Fuel Surcharge"
                  value={
                    isYodelOrEvri || rateValues.fuelSurcharge / 10 === 0
                      ? "N/A"
                      : rateValues.fuelPercent + "%"
                  }
                />
                <CellDivider />
              </>
            )}
            {financialCompany === 8 && externalQuote && (
              <Tooltip
                content={
                  <div>
                    {JSON.parse(externalQuote?.salesSurcharges).map(
                      (surcharge, index) => (
                        <div key={index}>
                          {`${surcharge.description}: $${(
                            surcharge.amount / 100
                          ).toFixed(2)}`}
                        </div>
                      )
                    )}
                  </div>
                }
              >
                <RateRowCellValue
                  title={
                    <span className="flex">
                      <AiOutlineInfoCircle />
                      {" Total Surcharges"}
                    </span>
                  }
                  value={externalQuote.totalSalesSurcharges / 100}
                />
              </Tooltip>
            )}
            <CellDivider />
            {financialCompany === 8 && (
              <>
                <RateRowCellValue
                  title="Fuel Surcharge"
                  value={
                    isYodelOrEvri
                      ? "N/A"
                      : externalQuote?.fuelSurchargePercentage + "%"
                  }
                />
                <CellDivider />
              </>
            )}
            <RateRowCellValue
              title="Total"
              value={
                isYodelOrEvri || adjustedTotal === 0 ? "N/A" : adjustedTotal
              }
            />
          </div>
        ) : null}
      </Panel>
    </div>
  );
};
