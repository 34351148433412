import { FetchSettingsResponseDto } from "./dtos/fetch-settings.response.dto";
import { fetchSingle } from "services/get";
import { apiPaths, customerApiBase } from "enum/paths.enum";
import { ISalesAccount } from "models/salesAccount/salesAccount.model";
import { patch } from "services/patch";
import { post } from "services/post";

export const fetchSettings = async (
  salesAccountUUID: ISalesAccount["uuid"]
): Promise<FetchSettingsResponseDto> => {
  return await fetchSingle(
    customerApiBase + "/" + salesAccountUUID + apiPaths.settings
  );
};

export const updateSettings = async (
  salesAccountUUID: ISalesAccount["uuid"],
  unit: string
): Promise<FetchSettingsResponseDto> => {
  return await post(
    customerApiBase + "/" + salesAccountUUID + apiPaths.settings,
    {unit}
  );
};

export const updateEnablePrintPackingSlip = async (
  salesAccountUUID: ISalesAccount["uuid"],
  shouldEnablePackingSlip: boolean
): Promise<FetchSettingsResponseDto> => {
  console.log("shouldEnablePackingSlip", shouldEnablePackingSlip);
  return await post(
    customerApiBase +
      "/" +
      salesAccountUUID +
      apiPaths.settings +
      "/packingSlip",
    { shouldEnablePackingSlip: shouldEnablePackingSlip }
  );
};

export const updateGroupBySKUForPackingSlip = async (
  salesAccountUUID: ISalesAccount["uuid"],
  shouldGroupItemsBySkuForPackingSlip: boolean
): Promise<FetchSettingsResponseDto> => {
  return await post(
    customerApiBase +
      "/" +
      salesAccountUUID +
      apiPaths.settings +
      "/packingSlipGroupBySKU",
    { shouldGroupItemsBySkuForPackingSlip: shouldGroupItemsBySkuForPackingSlip }
  );
};
