import { HeaderGroup } from "react-table";
import cx from "classnames";
import { TableCellIds, TableProps } from "./types";
import { createElement, useState } from "react";
import { TableCellLeftSlot } from "./TableCellLeftSlot";
import { useTheme } from "styled-components";
import { SortDirection } from "interfaces/api.interface";
import { ChevronDown20, ChevronUp20 } from "@carbon/icons-react";

type ITableHeaderCell<T extends object> = {
  column: HeaderGroup<T>;
} & Pick<TableProps<T>, "sort" | "handleColumnSort" | "erroredColumns">;

export const TableHeaderCell = <T extends object>({
  column,
  sort,
  handleColumnSort,
  erroredColumns,
}: ITableHeaderCell<T>) => {
  const { style } = column.getHeaderProps(column.getSortByToggleProps());

  const isCheckbox = column.id === TableCellIds.CHECKBOX;

  const isMenu = column.id === TableCellIds.MENU;

  const isSortable = column.enableSorting || false;

  const isSorted =
    isSortable && sort && Object.keys(sort).length > 0 && sort[column.id];

  const currentSortDirection = isSorted && sort[column.id];

  const [sortDirection, setSortDirection] = useState<SortDirection>(
    SortDirection.ASC
  );

  const handleSortClick = (sortDirection: SortDirection) => {
    if (handleColumnSort) {
      if (isSorted && sortDirection === currentSortDirection) {
        handleColumnSort({});
      } else {
        handleColumnSort({
          [column.id]: sortDirection,
        });
      }
    }
  };

  // const { isOpen, onOpen, onClose, onToggle } = useDisclosure();

  // const hasMenu = !isCheckbox && !isMenu;

  const theme = useTheme();
  const errorColumnValues = erroredColumns.map((element) => {
    return element.column;
  });
  return (
    <div
      {...column.getHeaderProps()}
      className={cx(
        `relative flex ${
          isCheckbox ? "justify-center" : "justify-between"
        } items-center leading-none whitespace-nowrap border-b px-4 border-r last:border-r-0 ${
          errorColumnValues.includes(column.id) ? "bg-red4" : ""
        }`,
        {
          "h-9": typeof column.Header !== "function",
        }
      )}
      style={{
        ...style,
        ...((isCheckbox || isMenu) && { padding: 0 }),
        borderColor: theme.borderColor,
        maxWidth: column.maxWidth,
      }}
      onClick={() => {
        //below is the columns that cannot be sorted
        if (
          column?.id !== "checkbox" &&
          column?.id !== "trackingCodes" &&
          column?.id !== "trackingStatus" &&
          column?.id !== "packageQuantity" &&
          column?.id !== "itemQuantity"
        ) {
          if (sortDirection === SortDirection.ASC) {
            setSortDirection(SortDirection.DESC);
          } else {
            setSortDirection(SortDirection.ASC);
          }
          handleSortClick(sortDirection);
        }
      }}
    >
      {column.icon && (
        <TableCellLeftSlot>
          {createElement(column.icon, {
            size: 15,
            className: "relative opacity-40",
          })}
        </TableCellLeftSlot>
      )}
      <div className="flex text-sm font-medium leading-none text-muted">
        {column.render("Header")}
      </div>
      {sort ? (
        <>
          {Object.keys(sort)[0] === column.id ? (
            <>
              {sortDirection === SortDirection.ASC ? (
                <ChevronDown20 />
              ) : (
                <ChevronUp20 />
              )}
            </>
          ) : null}
        </>
      ) : null}

      {/* <div
        {...column.getResizerProps()}
        className={styles.TableHeaderCell__resizer}
      /> */}
    </div>
  );
};
